import { environment } from '@env'
import { Injectable } from '@angular/core'
import { logger } from 'nx/src/utils/logger'
import { UserModel } from '@domain/user'
import { RoleType } from '@domain/authorization'

const USER_KEY = 'user-auth'
const TS_KEY = 'session-timeout'
const TIMEOUT = 10 * 3600 // timeout in seconds

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  user!: UserModel

  clean() {
    sessionStorage.clear()
  }

  saveLoginUser(user: UserModel) {
    logger.debug(`storage-service#saveLoginUser: user=`, JSON.stringify(user))
    this.removeLoginUser(user)
    if (user) {
      sessionStorage.setItem(USER_KEY, JSON.stringify(user))
      this.user = this.getLoginUser()
      this.setTimeout()
    }
  }

  removeLoginUser(user: any) {
    sessionStorage.removeItem(TS_KEY)
    sessionStorage.removeItem(USER_KEY)
  }

  getLoginUser(): any {
    const user = sessionStorage.getItem(USER_KEY)
    if (user && user !== 'undefined') {
      return JSON.parse(user)
    }
    return null
  }

  getLoginUserId(): any {
    const user = this.getLoginUser()
    if (user) {
      return user.id
    }
    return null
  }

  hasPermission(roles: RoleType[]): boolean {
    return this.isLoggedIn(this.user.login) && this.hasOneOfRoles(roles)
  }

  hasOneOfRoles(roles: RoleType[]): boolean {
    logger.debug('storage-service#hasOneOfRoles: roles=', roles)
    const userRoles = this.getLoginRoles()
    return userRoles && roles.some(role => userRoles.includes(role))
  }

  getLoginRoles(): string[] {
    const user = this.getLoginUser()
    logger.debug('storage-service#getLoginRoles: user=', user)
    if (user) {
      const roles: string[] = user.roles
      logger.debug('storage-service#getLoginRoles: roles=', roles)
      return roles
    }
    return []
  }

  setTimeout() {
    const maxMillis = (environment.sessionTimeout | TIMEOUT) * 1000
    const tout = Date.now() + maxMillis
    sessionStorage.setItem(TS_KEY, JSON.stringify(tout))
  }

  getTimeout(): number {
    const tout = sessionStorage.getItem(TS_KEY)
    return tout ? parseInt(tout) : 0
  }

  isTimedOut(): boolean {
    // TODO: make it run correctly in any condition
    const tout = this.getTimeout()
    const now = Date.now()
    console.debug('storage-service#isTimedOut: now=', now, ', tout=', tout, ', tout-now=', tout - now, ', isTimedOut=', (tout - now) < 0)
    if (tout < now) {
      this.clean()
      return true
    }
    return false
  }

  isLoggedIn(login: string): boolean {
    logger.debug('storageService#isLoggedIn: login=', login)
    if (!this.exist(login)) {
      logger.debug('storageService#isLoggedIn: exist=false')
      return false
    }
    if (this.isTimedOut()) {
      logger.debug('storageService#isLoggedIn: loggedIn=false')
      return false
    }
    const user = this.getLoginUser()
    logger.debug('storageService#isLoggedIn: loggedIn=', !!user, ', user=', user)
    return !!user
  }

  exist(login: string): boolean {
    return sessionStorage.getItem(USER_KEY) !== null
  }
}
